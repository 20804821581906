import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { actionUserLogout } from '../../store/actions/user.actions';
import './index.scss';
import { WechatOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageRoute } from '../../router/routes';

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="header-component-container">
      <div className="header-component-container-holder">
        <span className="header-component-left">
          <WechatOutlined />
          {location.pathname.includes(PageRoute.CHAT) ? (
            <Button
              onClick={() => {
                navigate(PageRoute.HOME);
              }}
            >
              Go back
            </Button>
          ) : null}
        </span>
        <Button
          onClick={() => {
            dispatch(actionUserLogout());
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
}

export default Header;
