import { Avatar, Card, List, Typography } from 'antd';
import React from 'react';
import { useChatState, useUserState } from '../../store/selectors';
import { useSelector } from 'react-redux';
import './index.scss';
import SocketApi from '../../ws';
import { useNavigate } from 'react-router-dom';
import { PageRoute } from '../../router/routes';

function Inbox() {
  const { inbox } = useSelector(useChatState);
  const { token, wsConnected } = useSelector(useUserState);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (token && wsConnected) {
      SocketApi.inboxSub({ token });
    }
    return () => {
      if (token && wsConnected) {
        SocketApi.inboxUnsub({ token });
      }
    };
  }, [token, wsConnected]);

  return (
    <Card className="inbox-component-container">
      <Typography.Title level={3}>Inbox</Typography.Title>
      <List
        itemLayout="horizontal"
        dataSource={inbox}
        renderItem={(item, index) => (
          <List.Item
            onClick={() => {
              navigate(`${PageRoute.CHAT}/${item.userId}`);
            }}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={`https://i.pravatar.cc/150?u=${item.username}`} />
              }
              title={<a>{item.username}</a>}
              description={`New messages (${item.numberOfNewMessages})`}
            />
          </List.Item>
        )}
      />
    </Card>
  );
}

export default Inbox;
