import React from 'react';
import Inbox from '../../components/Inbox';
import ActiveUsers from '../../components/ActiveUsers';
import './index.scss';
import AllUsers from '../../components/AllUsers';

function HomePage() {
  return (
    <div className="home-page-container">
      <Inbox />
      <ActiveUsers />
      <AllUsers />
    </div>
  );
}

export default HomePage;
