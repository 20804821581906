import {
  ActionTypes,
  ReduxActionPayload,
  UserLoadActiveUsersReduxActionData,
  UserLoadAllUsersReduxActionData,
  UserLoginReduxActionData,
} from '../actions/types';
import { UserState } from './types/user';

const defaultState: UserState = {
  isLoggedIn: false,
  token: null,
  username: null,
  userId: null,
  activeUsers: [],
  wsConnected: false,
  allUsers: [],
};

function userReducer(
  state = defaultState,
  action: ReduxActionPayload,
): UserState {
  switch (action.type) {
    case ActionTypes.USER_LOGIN:
      {
        const data: UserLoginReduxActionData = action.data;
        return {
          ...state,
          isLoggedIn: true,
          token: data.token,
          username: data.username,
          userId: data.userId,
        };
      }
      break;

    case ActionTypes.USER_LOGOUT:
      return defaultState;

    case ActionTypes.LOAD_ACTIVE_USERS:
      {
        const data: UserLoadActiveUsersReduxActionData = action.data;
        return {
          ...state,
          activeUsers: [...data.users],
        };
      }
      break;

    case ActionTypes.WS_CONNECTED: {
      return {
        ...state,
        wsConnected: true,
      };
    }

    case ActionTypes.WS_DISCONNECTED: {
      return {
        ...state,
        wsConnected: false,
      };
    }

    case ActionTypes.LOAD_ALL_USERS: {
      const data = action.data as UserLoadAllUsersReduxActionData;
      return {
        ...state,
        allUsers: [...data.users],
      };
    }

    default:
      return state;
  }
}

export default userReducer;
