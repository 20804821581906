import { Socket, io } from 'socket.io-client';
import {
  ActiveUsersMessage,
  AllUsersGetRequestWsMessage,
  AllUsersGetWsMessage,
  AuthenticatedMessage,
  InboxGetWsMessage,
  LoadMessagesRequestWsMessage,
  LoadMessagesWsMessage,
  NewMessageWsMessage,
  SeenMessagesRequestWsMessage,
  SendMessageWsMessage,
  SocketEmitCommands,
  // SocketEmitCommands,
  SocketIncomingCommands,
  SuccessMessage,
} from './constants';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import {
  actionUserLoadActiveUsers,
  actionUserLoadAllUsers,
  actionUserWsConnected,
  actionUserWsDisconnected,
} from '../store/actions/user.actions';
import {
  actionChatLoadInbox,
  actionChatLoadMessages,
  actionChatNewMessage,
} from '../store/actions/chat.actions';

class _SocketApi {
  private socket: Socket | null = null;

  private store: ToolkitStore | null = null;

  loadStore(store: ToolkitStore) {
    this.store = store;
  }

  private async reconnect() {
    const tokenAvailable = this.store?.getState()?.user?.token;
    if (tokenAvailable) {
      let retry = 0;
      const maxRetry = 10;
      let connected: boolean = false;

      while (retry < maxRetry && !connected) {
        connected = await this.connect(tokenAvailable);
        retry++;
      }
    }
  }

  async connect(token: string): Promise<boolean> {
    if (this.store) {
      this.socket = io(process.env.REACT_APP_WS_URL || 'http://localhost:3001');

      return await new Promise<boolean>((resolve) => {
        if (this.socket) {
          this.socket.on('connect', () => {
            this.socket?.emit(SocketIncomingCommands.AUTH, {
              token,
            });
            this.socket?.once(
              SocketIncomingCommands.AUTH,
              (payload: AuthenticatedMessage) => {
                if (this.store) {
                  console.log('WS: Connected!');
                  this.store?.dispatch(actionUserWsConnected());
                  resolve(true);
                } else {
                  this.disconnect();
                  resolve(false);
                }
              },
            );
          });

          this.socket?.on('disconnect', () => {
            console.log('WS: Disconnected!');
            this.disconnect().then(() => {
              this.reconnect();
            });
            resolve(false);
          });

          // HANDLE DATA

          this.socket.on(
            SocketIncomingCommands.ACTIVE_USERS_GET,
            (payload: ActiveUsersMessage) => {
              this.store?.dispatch(actionUserLoadActiveUsers(payload));
            },
          );

          this.socket.on(
            SocketEmitCommands.MESSAGES_NEW,
            (payload: NewMessageWsMessage) => {
              this.store?.dispatch(actionChatNewMessage({ message: payload }));
            },
          );

          this.socket.on(
            SocketIncomingCommands.INBOX_GET,
            (payload: InboxGetWsMessage) => {
              this.store?.dispatch(actionChatLoadInbox(payload));
            },
          );

          this.socket.on(
            SocketIncomingCommands.LOAD_MESSAGES,
            (payload: LoadMessagesWsMessage) => {
              this.store?.dispatch(actionChatLoadMessages(payload));
            },
          );

          this.socket.on(
            SocketIncomingCommands.ALL_USERS_GET,
            (payload: AllUsersGetWsMessage) => {
              this.store?.dispatch(actionUserLoadAllUsers(payload));
            },
          );
        }
        return false;
      });
    }
    return false;
  }

  async disconnect() {
    this.store?.dispatch(actionUserWsDisconnected());
    this.socket?.disconnect();
    this.socket = null;
  }

  async allUsersGet(payload: AllUsersGetRequestWsMessage) {
    if (this.socket && this.socket.connected) {
      return await new Promise((resolve) => {
        if (this.socket && this.socket.connected) {
          this.socket.emit(SocketIncomingCommands.ALL_USERS_GET, payload);
          resolve(true);
        }
        resolve(false);
      });
    }
  }

  async seenMessages(payload: SeenMessagesRequestWsMessage) {
    if (this.socket && this.socket.connected) {
      return await new Promise((resolve) => {
        if (this.socket && this.socket.connected) {
          this.socket.emit(SocketIncomingCommands.SEEN_MESSAGES, payload);
          this.socket.once(
            SocketIncomingCommands.SEEN_MESSAGES,
            (payload: SuccessMessage) => {
              resolve(payload.success);
            },
          );
        } else {
          resolve(false);
        }
      });
    }
  }

  async loadMessages(payload: LoadMessagesRequestWsMessage) {
    if (this.socket && this.socket.connected) {
      return await new Promise((resolve) => {
        if (this.socket && this.socket.connected) {
          this.socket.emit(SocketIncomingCommands.LOAD_MESSAGES, payload);
          this.seenMessages({ userId: payload.userId, token: payload.token });
          resolve(true);
        }
        resolve(false);
      });
    }
  }

  async inboxGet(payload: AuthenticatedMessage) {
    if (this.socket && this.socket.connected) {
      return await new Promise((resolve) => {
        if (this.socket && this.socket.connected) {
          this.socket.emit(SocketIncomingCommands.INBOX_GET, payload);
          resolve(true);
        }
        resolve(false);
      });
    }
  }

  async inboxSub(payload: AuthenticatedMessage) {
    if (this.socket && this.socket.connected) {
      return await new Promise((resolve) => {
        if (this.socket && this.socket.connected) {
          this.socket.emit(SocketIncomingCommands.INBOX_SUB, payload);
          this.socket.emit(SocketIncomingCommands.INBOX_GET, payload);
          this.socket.once(
            SocketIncomingCommands.INBOX_SUB,
            (payload: SuccessMessage) => {
              resolve(payload.success);
            },
          );
        }
        resolve(false);
      });
    }
  }

  async inboxUnsub(payload: AuthenticatedMessage) {
    if (this.socket && this.socket.connected) {
      return await new Promise((resolve) => {
        if (this.socket && this.socket.connected) {
          this.socket.emit(SocketIncomingCommands.INBOX_UNSUB, payload);
          this.socket.once(
            SocketIncomingCommands.INBOX_UNSUB,
            (payload: SuccessMessage) => {
              resolve(payload.success);
            },
          );
        }
        resolve(false);
      });
    }
  }

  async activeUsersSub(payload: AuthenticatedMessage) {
    if (this.socket && this.socket.connected) {
      return await new Promise((resolve) => {
        if (this.socket && this.socket.connected) {
          this.socket.emit(SocketIncomingCommands.ACTIVE_USERS_SUB, payload);
          this.socket.emit(SocketIncomingCommands.ACTIVE_USERS_GET, payload);
          this.socket.once(
            SocketIncomingCommands.ACTIVE_USERS_SUB,
            (payload: SuccessMessage) => {
              resolve(payload.success);
            },
          );
        }
        resolve(false);
      });
    }
  }

  async activeUsersUnsub(payload: AuthenticatedMessage) {
    if (this.socket && this.socket.connected) {
      return await new Promise((resolve) => {
        if (this.socket && this.socket.connected) {
          this.socket.emit(SocketIncomingCommands.ACTIVE_USERS_UNSUB, payload);
          this.socket.once(
            SocketIncomingCommands.ACTIVE_USERS_UNSUB,
            (payload: SuccessMessage) => {
              resolve(payload.success);
            },
          );
        }
        resolve(false);
      });
    }
  }

  async sendMessage(payload: SendMessageWsMessage) {
    if (this.socket && this.socket.connected) {
      return await new Promise((resolve) => {
        if (this.socket && this.socket.connected) {
          this.socket.emit(SocketIncomingCommands.SEND_MESSAGE, payload);
          this.socket.once(
            SocketIncomingCommands.SEND_MESSAGE,
            (payload: SuccessMessage) => {
              resolve(payload.success);
            },
          );
        } else {
          resolve(false);
        }
      });
    }
  }
}

const SocketApi = new _SocketApi();

export default SocketApi;
