import {
  ActionTypes,
  ChatLoadInboxReduxActionData,
  ChatLoadMessagesReduxActionData,
  ChatLoadMoreMessagesReduxActionData,
  ChatNewMessageReduxActionData,
  ReduxActionPayload,
} from '../actions/types';
import { ChatState } from './types/user';

const defaultState: ChatState = {
  inbox: [],
  messages: [],
  limit: 100,
  offset: 0,
  chatWithUserId: null,
};

function chatReducer(
  state = defaultState,
  action: ReduxActionPayload,
): ChatState {
  switch (action.type) {
    case ActionTypes.LOAD_INBOX:
      {
        const data = action.data as ChatLoadInboxReduxActionData;
        return {
          ...state,
          inbox: [...data.inbox],
        };
      }
      break;

    case ActionTypes.LOAD_MESSAGES: {
      const data = action.data as ChatLoadMessagesReduxActionData;
      return {
        ...state,
        messages: [...data.messages],
      };
    }

    case ActionTypes.LOAD_MORE_MESSAGES: {
      const data = action.data as ChatLoadMoreMessagesReduxActionData;
      return {
        ...state,
        messages: [...data.messages, ...state.messages],
      };
    }

    case ActionTypes.NEW_MESSAGE: {
      const data = action.data as ChatNewMessageReduxActionData;
      return {
        ...state,
        messages: [...state.messages, data.message],
      };
    }
    default:
      return state;
  }
}

export default chatReducer;
