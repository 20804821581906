import React from 'react';
import Messages from '../../components/Messages';
import { useNavigate, useParams } from 'react-router-dom';
import { PageRoute } from '../../router/routes';
import './index.scss';
import SendMessageActionBox from '../../components/SendMessage';

function ChatPage() {
  const { withUserId } = useParams();
  const parsedWithUserId = withUserId ? parseInt(withUserId, 10) : null;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!withUserId || Number.isNaN(parseInt(withUserId, 10))) {
      navigate(PageRoute.HOME);
    }
  }, [withUserId]);

  if (parsedWithUserId) {
    return (
      <div className="chat-page-container">
        <Messages withUserId={parsedWithUserId} />
        <SendMessageActionBox withUserId={parsedWithUserId} />
      </div>
    );
  }
  return null;
}

export default ChatPage;
