import { Avatar, Card, List, Typography } from 'antd';
import React from 'react';
import { useUserState } from '../../store/selectors';
import { useSelector } from 'react-redux';
import './index.scss';
import SocketApi from '../../ws';
import { useNavigate } from 'react-router-dom';
import { PageRoute } from '../../router/routes';

function ActiveUsers() {
  const { activeUsers, token, wsConnected } = useSelector(useUserState);
  const navigate = useNavigate();
  const firstTime = React.useRef(true);

  React.useEffect(() => {
    if (token && wsConnected) {
      SocketApi.activeUsersSub({ token });
    }
    return () => {
      if (token && wsConnected) {
        SocketApi.activeUsersUnsub({ token });
      }
    };
  }, [token, wsConnected]);

  React.useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;
    } else if (token && activeUsers?.length) {
      SocketApi.allUsersGet({ token });
    }
  }, [token, activeUsers]);

  return (
    <Card className="active-users-component-container">
      <Typography.Title level={3}>Active Users</Typography.Title>
      <List
        itemLayout="horizontal"
        dataSource={activeUsers}
        renderItem={(item, index) => (
          <List.Item
            onClick={() => {
              navigate(`${PageRoute.CHAT}/${item.userId}`);
            }}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={`https://i.pravatar.cc/150?u=${item.username}`} />
              }
              title={<a>{item.username}</a>}
              description={`Start Chat`}
            />
          </List.Item>
        )}
      />
    </Card>
  );
}

export default ActiveUsers;
