import { combineReducers } from '@reduxjs/toolkit';
import { PersistConfig } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { ChatState, UserState } from './types/user';
import userReducer from './user.reducer';
import chatReducer from './chat.reducer';

const persistUserConfig: PersistConfig<UserState> = {
  key: 'user',
  storage,
  blacklist: ['wsConnected'],
};

const persistChatConfig: PersistConfig<ChatState> = {
  key: 'chat',
  storage,
};

const rootReducer = combineReducers({
  user: persistReducer(persistUserConfig, userReducer),
  chat: persistReducer(persistChatConfig, chatReducer),
});

export default rootReducer;
