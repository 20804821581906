import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PageRoute } from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../store/selectors';
import React from 'react';
import SocketApi from '../ws';
import LoginPage from '../pages/LoginPage';
import HomePage from '../pages/HomePage';
import { message } from 'antd';
import { actionUserLogout } from '../store/actions/user.actions';
import Header from '../components/Header';
import SignupPage from '../pages/SignupPage';
import ChatPage from '../pages/ChatPage';

function AppRouter() {
  const { token, isLoggedIn } = useSelector(useUserState);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (token) {
      SocketApi.connect(token).then((res) => {
        if (!res) {
          message.success('Error connecting to chat!');
          dispatch(actionUserLogout());
        }
      });
    }
    return () => {
      if (token) {
        SocketApi.disconnect();
      }
    };
  }, [token]);

  return (
    <BrowserRouter>
      {token && isLoggedIn ? (
        <>
          <Header />
          <Routes>
            <Route path={PageRoute.HOME} element={<HomePage />} />
            <Route
              path={`${PageRoute.CHAT}/:withUserId`}
              element={<ChatPage />}
            />
            <Route path={'*'} element={<Navigate to={PageRoute.HOME} />} />
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route path={PageRoute.LOGIN} element={<LoginPage />} />
            <Route path={PageRoute.SIGNUP} element={<SignupPage />} />
            <Route path={'*'} element={<Navigate to={PageRoute.LOGIN} />} />
          </Routes>
        </>
      )}
    </BrowserRouter>
  );
}

export default AppRouter;
