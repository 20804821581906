export enum SocketIncomingCommands {
  ACTIVE_USERS_SUB = 'active-users-sub',
  ACTIVE_USERS_UNSUB = 'active-users-unsub',
  ACTIVE_USERS_GET = 'active-users-get',
  LOAD_MESSAGES = 'load-messages',
  SEND_MESSAGE = 'send-message',
  INBOX_SUB = 'inbox-sub',
  INBOX_UNSUB = 'inbox-unsub',
  INBOX_GET = 'inbox-get',
  SEEN_MESSAGES = 'seen-messages',
  AUTH = 'auth',
  ALL_USERS_GET = 'all-users-get',
}

export enum SocketSubscriptions {
  ACTIVE_USERS = 'active-users',
  INBOX = 'inbox',
}

export enum SocketEmitCommands {
  ACTIVE_USERS = 'active-users',
  MESSAGES_NEW = 'messages-new',
  INBOX_UPDATE = 'inbox-update',
}

export interface ActiveUsersUser {
  username: string;
  userId: number;
}

export interface ActiveUsersMessage {
  users: ActiveUsersUser[];
}

export interface AuthenticatedMessage {
  token: string;
}

export interface SuccessMessage {
  success: boolean;
}

export interface LoadMessagesRequestWsMessage extends AuthenticatedMessage {
  userId: number;
  limit: number;
  offset: number;
}

export interface SendMessageWsMessage extends AuthenticatedMessage {
  userId: number;
  content: string;
}

export interface InboxGetMessageWsMessage {
  userId: number;
  username: string;
  numberOfNewMessages: number;
}

export interface InboxGetWsMessage {
  inbox: InboxGetMessageWsMessage[];
}

export interface LoadMessagesMessageWsMessage {
  createdAt: string;
  content: string;
  username: string;
  userId: number;
}

export interface LoadMessagesWsMessage {
  messages: LoadMessagesMessageWsMessage[];
}

export interface LoadMoreMessagesWsMessage {
  messages: LoadMessagesMessageWsMessage[];
}

export interface SeenMessagesRequestWsMessage extends AuthenticatedMessage {
  userId: number;
}

export interface NewMessageWsMessage extends LoadMessagesMessageWsMessage {}

export interface AllUsersGetRequestWsMessage extends AuthenticatedMessage {}

export interface AllUsersGetUserWsMessage {
  userId: number;
  username: string;
}

export interface AllUsersGetWsMessage {
  users: AllUsersGetUserWsMessage[];
}
