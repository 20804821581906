import { Avatar, Card, List, Typography } from 'antd';
import React from 'react';
import { useUserState } from '../../store/selectors';
import { useSelector } from 'react-redux';
import './index.scss';
import SocketApi from '../../ws';
import { useNavigate } from 'react-router-dom';
import { PageRoute } from '../../router/routes';

function AllUsers() {
  const { allUsers, token, wsConnected } = useSelector(useUserState);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (token && wsConnected) {
      SocketApi.allUsersGet({ token });
    }
  }, [token, wsConnected]);

  return (
    <Card className="all-users-component-container">
      <Typography.Title level={3}>All Users</Typography.Title>
      <List
        itemLayout="horizontal"
        dataSource={allUsers}
        renderItem={(item, index) => (
          <List.Item
            onClick={() => {
              navigate(`${PageRoute.CHAT}/${item.userId}`);
            }}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={`https://i.pravatar.cc/150?u=${item.username}`} />
              }
              title={<a>{item.username}</a>}
              description={`Start Chat`}
            />
          </List.Item>
        )}
      />
    </Card>
  );
}

export default AllUsers;
