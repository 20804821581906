import {
  ActionTypes,
  ReduxActionPayload,
  UserLoadActiveUsersReduxActionData,
  UserLoadAllUsersReduxActionData,
  UserLoginReduxActionData,
} from './types';

export function actionUserLogin(
  data: UserLoginReduxActionData,
): ReduxActionPayload {
  return {
    type: ActionTypes.USER_LOGIN,
    data,
  };
}

export function actionUserLogout(): ReduxActionPayload {
  return {
    type: ActionTypes.USER_LOGOUT,
    data: {},
  };
}

export function actionUserLoadActiveUsers(
  data: UserLoadActiveUsersReduxActionData,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_ACTIVE_USERS,
    data,
  };
}

export function actionUserWsConnected() {
  return {
    type: ActionTypes.WS_CONNECTED,
    data: {},
  };
}

export function actionUserWsDisconnected() {
  return {
    type: ActionTypes.WS_DISCONNECTED,
    data: {},
  };
}

export function actionUserLoadAllUsers(
  data: UserLoadAllUsersReduxActionData,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_ALL_USERS,
    data,
  };
}
