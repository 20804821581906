import {
  ActiveUsersUser,
  AllUsersGetUserWsMessage,
  InboxGetMessageWsMessage,
  LoadMessagesMessageWsMessage,
} from '../../ws/constants';

export enum ActionTypes {
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGOUT = 'USER_LOGOUT',
  LOAD_INBOX = 'LOAD_INBOX',
  LOAD_MESSAGES = 'LOAD_MESSAGES',
  LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES',
  LOAD_ACTIVE_USERS = 'LOAD_ACTIVE_USERS',
  WS_CONNECTED = 'WS_CONNECTED',
  WS_DISCONNECTED = 'WS_DISCONNECTED',
  NEW_MESSAGE = 'NEW_MESSAGE',
  LOAD_ALL_USERS = 'LOAD_ALL_USERS',
}

export interface ReduxActionPayload {
  type: ActionTypes;
  data: any;
}

export interface UserLoginReduxActionData {
  token: string;
  username: string;
  userId: number;
}

export interface UserLoadActiveUsersReduxActionData {
  users: ActiveUsersUser[];
}

export interface UserLoadAllUsersReduxActionData {
  users: AllUsersGetUserWsMessage[];
}

export interface ChatLoadInboxReduxActionData {
  inbox: InboxGetMessageWsMessage[];
}

export interface ChatLoadMessagesReduxActionData {
  messages: LoadMessagesMessageWsMessage[];
}

export interface ChatNewMessageReduxActionData {
  message: LoadMessagesMessageWsMessage;
}

export interface ChatLoadMoreMessagesReduxActionData {
  messages: LoadMessagesMessageWsMessage[];
}
