import Card from 'antd/es/card/Card';
import { Button, Form, Input, Typography, message } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { apiAuthSignup } from '../../api';
import { actionUserLogin } from '../../store/actions/user.actions';
import './index.scss';
import AnchorLink from 'antd/es/anchor/AnchorLink';
import { PageRoute } from '../../router/routes';

function SignupPage() {
  const dispatch = useDispatch();
  const signupCallback = React.useCallback(async (values: any) => {
    if (values.username?.length && values.password?.length) {
      const result = await apiAuthSignup(values);
      if (result) {
        dispatch(
          actionUserLogin({
            token: result.access_token,
            username: result.username,
            userId: result.userId,
          }),
        );
        message.success({ content: 'Registered!' });
      } else {
        message.error({ content: 'Already exists!' });
      }
    } else {
      message.error({ content: 'Must not be empty!' });
    }
  }, []);

  return (
    <div className="signup-page-container">
      <Card>
        <Typography.Title level={3}>Signup</Typography.Title>
        <Form name="signup" onFinish={signupCallback}>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        <AnchorLink title="Login" href={PageRoute.LOGIN} />
      </Card>
    </div>
  );
}

export default SignupPage;
