import { useSelector } from 'react-redux';
import { useChatState, useUserState } from '../../store/selectors';
import React from 'react';
import './index.scss';
import SocketApi from '../../ws';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PageRoute } from '../../router/routes';

export interface MessagesProps {
  withUserId: number;
}

function Messages({ withUserId }: MessagesProps) {
  const { userId, wsConnected, token } = useSelector(useUserState);
  const { messages } = useSelector(useChatState);
  const navigate = useNavigate();
  const holderRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  function scrollToNewest() {
    if (holderRef?.current) {
      holderRef.current.scrollTo(0, holderRef.current.scrollHeight);
    }
  }

  React.useEffect(() => {
    if (withUserId && token && wsConnected) {
      SocketApi.loadMessages({
        userId: withUserId,
        token,
        limit: 9999,
        offset: 0,
      }).then((res) => {
        if (!res) {
          message.error('Error loading messages!');
          navigate(PageRoute.HOME);
        }
      });
    }
    return () => {
      if (withUserId && token && wsConnected) {
        SocketApi.seenMessages({ token, userId: withUserId }).then(() => {
          SocketApi.inboxGet({ token });
        });
      }
    };
  }, [token, wsConnected]);

  React.useEffect(() => {
    if (token && wsConnected) {
      SocketApi.inboxSub({ token });
    }
    return () => {
      if (token && wsConnected) {
        SocketApi.inboxUnsub({ token });
      }
    };
  }, [token, wsConnected]);

  React.useEffect(() => {
    setTimeout(() => {
      scrollToNewest();
    }, 0);
  }, [messages]);

  return (
    <div className="messages-component-holder" ref={holderRef}>
      <div className="messages-component-container">
        {messages.map((msg, index) => (
          <div
            key={`${index}${msg.userId}${msg.createdAt}`}
            className={`messages-component-message-block ${
              msg.userId === userId ? 'outcoming' : 'incoming'
            }`}
          >
            <div
              className={`messages-component-message-box ${
                msg.userId === userId ? 'outcoming' : 'incoming'
              }`}
            >
              <span
                className={`messages-component-message-author ${
                  msg.userId === userId ? 'outcoming' : 'incoming'
                }`}
              >
                {msg.username}
              </span>
              <span
                className={`messages-component-message-content ${
                  msg.userId === userId ? 'outcoming' : 'incoming'
                }`}
              >
                {msg.content}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Messages;
