import { ApiLoginBody, ApiLoginResponse, ApiSignupResponse } from './types';

const API_URL = process.env.REACT_APP_API_URL;

export async function apiAuthLogin(
  body: ApiLoginBody,
): Promise<ApiLoginResponse | null> {
  const url = new URL(`${API_URL}/api/auth/login`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as ApiLoginResponse;
  }
  return null;
}

export async function apiAuthSignup(
  body: ApiLoginBody,
): Promise<ApiLoginResponse | null> {
  const url = new URL(`${API_URL}/api/auth/signup`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as ApiSignupResponse;
  }
  return null;
}
