import {
  ActionTypes,
  ChatLoadInboxReduxActionData,
  ChatLoadMessagesReduxActionData,
  ChatNewMessageReduxActionData,
  ReduxActionPayload,
} from './types';

export function actionChatLoadInbox(
  data: ChatLoadInboxReduxActionData,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_INBOX,
    data,
  };
}

export function actionChatLoadMessages(
  data: ChatLoadMessagesReduxActionData,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_MESSAGES,
    data,
  };
}

export function actionChatNewMessage(
  data: ChatNewMessageReduxActionData,
): ReduxActionPayload {
  return {
    type: ActionTypes.NEW_MESSAGE,
    data,
  };
}
