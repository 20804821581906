import React from 'react';
import './index.scss';
import TextArea from 'antd/es/input/TextArea';
import { Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import SocketApi from '../../ws';
import { actionChatNewMessage } from '../../store/actions/chat.actions';

export interface SendMessageActionBoxProps {
  withUserId: number;
}

function SendMessageActionBox({ withUserId }: SendMessageActionBoxProps) {
  const { token, wsConnected, userId, username } = useSelector(useUserState);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [text, setText] = React.useState<string>('');
  const dispatch = useDispatch();

  const submitMessage = React.useCallback(async () => {
    if (
      userId &&
      username &&
      withUserId &&
      token &&
      wsConnected &&
      text.length
    ) {
      setLoading(true);
      const res = await SocketApi.sendMessage({
        token,
        content: text.trim(),
        userId: withUserId,
      });
      if (!res) {
        message.error('Error sending message!');
      } else {
        dispatch(
          actionChatNewMessage({
            message: {
              userId,
              username,
              content: text,
              createdAt: new Date().toISOString(),
            },
          }),
        );
      }
      setLoading(false);
      setText('');
    }
  }, [userId, username, token, wsConnected, withUserId, text]);

  return (
    <div className="send-message-action-box-component-container">
      <TextArea
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        autoSize={{ minRows: 2 }}
        className="send-message-action-box-component-input"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitMessage();
          }
        }}
        autoFocus
      />
      <Button
        className="send-message-action-box-component-button"
        type="primary"
        onClick={submitMessage}
        disabled={loading}
        loading={loading}
      >
        Send Message
      </Button>
    </div>
  );
}

export default SendMessageActionBox;
