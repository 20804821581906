import { RootState } from './reducers/types/root';
import { ChatState } from './reducers/types/user';

export function useUserState(state: RootState) {
  return state.user;
}

export function useChatState(state: RootState) {
  return state.chat;
}
